import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Col, Row } from "react-bootstrap";

function HalloweenContactFormMini() {
	const [state, handleSubmit] = useForm("mrbzwnze");
	if (state.succeeded) {
		return (
			<div>
				<Form
					className="p-5 dark-form"
					onSubmit={handleSubmit}
					style={{ backgroundColor: "#181819", borderRadius: 12 }}
				>
					<Row>
						<Col lg={6}>
							<Form.Group className="mb-5" controlId="name">
								<Form.Control
									name="name"
									placeholder="Full Name *"
									type="text"
									className="border-0 border-bottom text-white"
									style={{ backgroundColor: "transparent", borderRadius: 0 }}
								/>
							</Form.Group>
							<ValidationError
								prefix="Name"
								field="name"
								errors={state.errors}
							/>
							<Form.Group className="mb-5" controlId="email">
								<Form.Control
									name="_replyto"
									type="email"
									placeholder="Email Address *"
									className="border-0 border-bottom  text-white"
									style={{ backgroundColor: "transparent", borderRadius: 0 }}
								/>
							</Form.Group>
							<ValidationError
								prefix="Email"
								field="email"
								errors={state.errors}
							/>
							<Form.Group className="mb-5" controlId="telephone">
								<Form.Control
									type="tel"
									name="telephone"
									placeholder="Phone Number *"
									className="border-0 border-bottom text-white"
									style={{ backgroundColor: "transparent", borderRadius: 0 }}
								/>
							</Form.Group>

							<ValidationError
								prefix="Telephone"
								field="telephone"
								errors={state.errors}
							/>
						</Col>
						<Col lg={6}>
							<Form.Group className="mb-3" controlId="message">
								<Form.Label style={{ color: "#8B8B8B" }}>
									Message <span className="text-danger">*</span>
								</Form.Label>
								<Form.Control
									className="bg-black border-0 text-white h-100"
									name="message"
									as="textarea"
									rows={8}
								/>
							</Form.Group>

							<ValidationError
								prefix="Message"
								field="message"
								errors={state.errors}
							/>
						</Col>
					</Row>

					{/* <div
        class="g-recaptcha"
        data-sitekey=""
      ></div> */}
					<div className="d-flex justify-content-end">
						<Button
							className="text-white text-uppercase w-100 w-md-auto fs-5 px-4 py-2"
							type="submit"
							id="contact-send-btn"
							disabled={state.submitting}
						>
							Send
						</Button>
					</div>
				</Form>
				<div className="d-none">{(window.location = "/thank-you")}</div>
			</div>
		);
	}
	return (
		<div className="contactForm">
			<Form
				className="p-lg-5 p-4 py-5 dark-form"
				onSubmit={handleSubmit}
				style={{ backgroundColor: "#181819", borderRadius: 12 }}
			>
				<Row>
					<Col lg={6}>
						<Form.Group className="mb-5" controlId="name">
							<Form.Control
								name="name"
								placeholder="Full Name *"
								required
								type="text"
								className="border-0 border-bottom text-white"
								style={{ backgroundColor: "transparent", borderRadius: 0 }}
							/>
						</Form.Group>
						<ValidationError prefix="Name" field="name" errors={state.errors} />
						<Form.Group className="mb-5" controlId="email">
							<Form.Control
								required
								name="_replyto"
								type="email"
								placeholder="Email Address *"
								className="border-0 border-bottom  text-white"
								style={{ backgroundColor: "transparent", borderRadius: 0 }}
							/>
						</Form.Group>
						<ValidationError
							prefix="Email"
							field="email"
							errors={state.errors}
						/>
						<Form.Group className="mb-5" controlId="telephone">
							<Form.Control
								required
								type="tel"
								name="telephone"
								placeholder="Phone Number *"
								className="border-0 border-bottom text-white"
								style={{ backgroundColor: "transparent", borderRadius: 0 }}
							/>
						</Form.Group>

						<ValidationError
							prefix="Telephone"
							field="telephone"
							errors={state.errors}
						/>
					</Col>
					<Col lg={6}>
						<Form.Group className="mb-3" controlId="message">
							<Form.Label style={{ color: "#8B8B8B" }}>
								Message <span className="text-danger">*</span>
							</Form.Label>
							<Form.Control
								required
								className="bg-black border-0 text-white h-100"
								name="message"
								as="textarea"
								rows={8}
							/>
						</Form.Group>

						<ValidationError
							prefix="Message"
							field="message"
							errors={state.errors}
						/>
					</Col>
				</Row>

				{/* <div
        class="g-recaptcha"
        data-sitekey=""
      ></div> */}
				<div className="d-flex justify-content-end">
					<Button
						className="text-white text-uppercase w-100 w-md-auto fs-5 px-4 py-2"
						type="submit"
						id="contact-send-btn"
						disabled={state.submitting}
					>
						Send
					</Button>
				</div>
			</Form>
		</div>
	);
}
export default HalloweenContactFormMini;
