import React from "react";
import AddressAndTransport from "../components/AddressAndTransport";
import { Helmet } from "react-helmet";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import { graphql, useStaticQuery } from "gatsby";
import {
	Button,
	Col,
	Container,
	Dropdown,
	Form,
	Row,
	Stack,
	DropdownButton,
} from "react-bootstrap";
import { parseNestedHTML } from "../Utils";
import HTMLReactParser from "html-react-parser";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import NinjaStarSVG from "../components/svg/NinjaStarSVG";
import { ValidationError } from "@formspree/react";
import SafeHtmlParser from "../components/safe-html-parser";
import ReactPlayer from "react-player";
import DesktopLogo from "../images/background elements.svg";
import PricingImage from "../images/pricing-mini.svg";
import { MdOpacity } from "react-icons/md";
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookSquare } from "react-icons/fa";
import ReviewSection from "../components/review-section";
import ContactForm from "../components/contactForm";
import XmasContactForm from "../components/xmas-contact-form";
import HalloweenContactForm from "../components/halloween-contact-form";
import { BgImage } from "gbimage-bridge";
import HalloweenContactFormMini from "../components/halloween-contact-form-mini";

const NinjaHalloweenMini = () => {
	const data = useStaticQuery(graphql`
		query {
			bgImg: wpMediaItem(title: { eq: "background halloween morning" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			wpHalloweenPartyMini {
				seoFields {
					localBusinessSchema
					metaDescription
					opengraphDescription
					opengraphTitle
					title
					image {
						node {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}

				halloweenPartyFields {
					btnText
					socialBannerTitle
					heroSection {
						title
						smallHeading
						description
						buttonText
					}
					glimpse {
						btnText
						image1 {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						image2 {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						image3 {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						image4 {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						image5 {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}

					reviews {
						buttonText
						title
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const NBPPage = data.wpHalloweenPartyMini.halloweenPartyFields;
	const seoFields = data.wpHalloweenPartyMini.seoFields;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Halloween Mini Morning",
				item: {
					url: `${siteUrl}/halloween-mini-morning`,
					id: `${siteUrl}/halloween-mini-morning`,
				},
			},
		],
	};
	return (
		<div>
			<Helmet>
				<script
					src="https://www.google.com/recaptcha/api.js"
					async
					defer
				></script>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/halloween-mini-morning`,
					title: seoFields?.opengraphTitle,
					description: seoFields?.opengraphDescription,
					images: [
						{
							url: `${seoFields?.image?.node?.localFile.publicURL}`,
							width: `${seoFields?.image?.node?.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node?.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node?.altText}`,
						},
					],
				}}
			/>
			<Layout>
				<section className="position-relative ">
					<div className="position-relative hero-carousal-update">
						<div
							style={{
								background: "#2E146F",
								borderTop: "1px solid #FFF",
								borderBottom: "1px solid #FFF",
								overflow: "hidden",
							}}
							className="position-absolute h-100 w-100"
						>
							<img
								src={DesktopLogo}
								style={{ zIndex: 0, opacity: 1 }}
								alt="Halloween"
								className="position-absolute bottom-0 start-0 w-100"
							/>

							<Container
								style={{ zIndex: 2 }}
								className="h-100 position-relative"
							>
								<Row className="align-items-center justify-content-center h-100">
									<Col lg={10} xl={8} className="text-center">
										<div className="h-100 ">
											<p
												className="fs-1 text-white emilys"
												dangerouslySetInnerHTML={{
													__html: NBPPage.heroSection.smallHeading.replace(
														/(\d+)(st)/g,
														"$1<sup>$2</sup>"
													),
												}}
											></p>
											<div className="stylishText text-uppercase text-center center-text ">
												<SafeHtmlParser
													htmlContent={NBPPage.heroSection.title}
												/>
											</div>
											<p className="text-white fs-5 pb-0 mb-0">
												{NBPPage.heroSection.description}
											</p>
											<Button
												variant="white"
												href="#form"
												className="text-primary text-uppercase  my-3 my-lg-4 fs-5 px-xl-4 py-2"
											>
												{NBPPage.heroSection.buttonText}
											</Button>
											<p className="text-white">{NBPPage.heroSection.date}</p>
										</div>
									</Col>
								</Row>
							</Container>
						</div>
					</div>
				</section>

				<section>
					<BgImage
						style={{ backgroundPosition: "bottom" }}
						Tag="section"
						className=" py-5 py-lg-7"
						image={getImage(
							data.bgImg.localFile?.childImageSharp?.gatsbyImageData
						)}
					>
						<div
							style={{ background: "black", opacity: 0.5, zIndex: 1 }}
							className="position-absolute w-100 h-100 start-0 top-0"
						></div>
						<Container className="position-relative" style={{ zIndex: 2 }}>
							<Row className="mt-lg-5 justify-content-center">
								<Col lg={6} className="text-center">
									<img src={PricingImage} alt="Halloween" className=" w-100 " />
									<Button
										variant="white"
										href="#form"
										className="text-primary text-uppercase  my-3 my-lg-4 fs-5 px-xl-4 py-2"
									>
										{NBPPage.btnText}
									</Button>
								</Col>
							</Row>
						</Container>
					</BgImage>
				</section>

				<section>
					<div
						className="py-3"
						style={{
							backgroundColor: "#2E146F",
						}}
					>
						<Container>
							<Row>
								<Col
									lg={6}
									className="position-relative text-center p-0 session3"
								>
									<Stack
										direction="horizontal"
										gap={3}
										className="align-items-center d-none d-md-flex justify-content-center justify-content-md-start "
									>
										<div className="d-flex align-items-center">
											<a
												target="_blank"
												rel="noreferrer"
												className="white-link-black  d-inline-block me-3  fs-2 social-icon"
												href="https://www.instagram.com/ninjaeastbourne/"
											>
												<AiFillInstagram />
											</a>
											<a
												target="_blank"
												rel="noreferrer"
												className="white-link-black me-xl-4 d-inline-block   fs-2 social-icon"
												href="https://www.facebook.com/NinjaArenaEastbourne"
											>
												<FaFacebookSquare />
											</a>
										</div>
										<div className="title stylishText">
											<SafeHtmlParser htmlContent={NBPPage.socialBannerTitle} />
										</div>
									</Stack>
									<Stack
										direction="horizontal"
										gap={3}
										className="align-items-start d-md-none justify-content-center justify-content-md-start "
									>
										<div className="title stylishText position-relative">
											<SafeHtmlParser htmlContent={NBPPage.socialBannerTitle} />
											<div className="d-flex me-3 position-absolute top-0 end-0 align-items-center">
												<a
													target="_blank"
													rel="noreferrer"
													className="white-link-black  d-inline-block me-3  fs-2 social-icon"
													href="https://www.instagram.com/ninjaeastbourne/"
												>
													<AiFillInstagram />
												</a>
												<a
													target="_blank"
													rel="noreferrer"
													className="white-link-black me-xl-4 d-inline-block   fs-2 social-icon"
													href="https://www.facebook.com/NinjaArenaEastbourne"
												>
													<FaFacebookSquare />
												</a>
											</div>
										</div>
									</Stack>
								</Col>
							</Row>
						</Container>
					</div>
				</section>
				<section className="pt-5 pt-lg-7">
					<Container>
						<h2 className="display-5 pb-4 text-center text-uppercase">
							{NBPPage.reviews.title}
						</h2>

						<ReviewSection fromIndex={0} toIndex={6} black />
						<div className="flex d-flex justify-content-center m-3">
							<Button
								target="_blank"
								rel="noreferrer"
								href="https://www.google.com/maps/place/Ninja+Arena,+Eastbourne/@50.769601,0.28568,14z/data=!4m8!3m7!1s0x47df7341975be679:0x63c07fe02c3335f3!8m2!3d50.7696006!4d0.2856804!9m1!1b1!16s%2Fg%2F11t5y526t4?hl=en&entry=ttu"
								className="text-white w-100 w-md-auto text-uppercase mt-4 fs-5 px-4 py-2"
							>
								{NBPPage.reviews.buttonText}
							</Button>
						</div>
					</Container>
				</section>
				<section className="py-5 py-lg-7">
					<Container>
						<Row className="g-3">
							<Col lg={6}>
								<div
									className="h-100 w-100"
									style={{ borderRadius: "16px", overflow: "hidden" }}
								>
									<GatsbyImage
										image={
											NBPPage.glimpse.image1?.node.localFile?.childImageSharp
												?.gatsbyImageData
										}
										className="w-100 h-100"
										alt={NBPPage.glimpse.image1?.node?.altText}
									/>
								</div>
							</Col>
							<Col lg={6}>
								<Row className="g-3">
									<Col xs={6}>
										<div
											className="h-100"
											style={{ borderRadius: "16px", overflow: "hidden" }}
										>
											<GatsbyImage
												image={
													NBPPage.glimpse.image2?.node.localFile
														?.childImageSharp?.gatsbyImageData
												}
												className="w-100 h-100"
												alt={NBPPage.glimpse.image2?.node?.altText}
											/>
										</div>
									</Col>
									<Col xs={6}>
										<div style={{ borderRadius: "16px", overflow: "hidden" }}>
											<GatsbyImage
												image={
													NBPPage.glimpse.image3?.node.localFile
														?.childImageSharp?.gatsbyImageData
												}
												className="w-100 h-100"
												alt={NBPPage.glimpse.image3?.node?.altText}
											/>
										</div>
									</Col>
									<Col xs={6}>
										<div style={{ borderRadius: "16px", overflow: "hidden" }}>
											<GatsbyImage
												image={
													NBPPage.glimpse.image4?.node.localFile
														?.childImageSharp?.gatsbyImageData
												}
												className="w-100 h-100"
												alt={NBPPage.glimpse.image4?.node?.altText}
											/>
										</div>
									</Col>
									<Col xs={6}>
										<div style={{ borderRadius: "16px", overflow: "hidden" }}>
											<GatsbyImage
												image={
													NBPPage.glimpse.image5?.node.localFile
														?.childImageSharp?.gatsbyImageData
												}
												className="w-100 h-100"
												alt={NBPPage.glimpse.image5?.node?.altText}
											/>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col className="text-center">
								<Button
									href="#form"
									className="text-white w-100 w-md-auto text-uppercase mt-5 fs-5 px-4 py-2"
								>
									{NBPPage.glimpse.btnText}
								</Button>
							</Col>
						</Row>
					</Container>
				</section>
				<section id="form" className="py-5 py-lg-7">
					<Container>
						<Row>
							<Col>
								<h2 className="display-5 pb-3 text-uppercase text-center">
									enquire now
								</h2>

								<HalloweenContactFormMini />
							</Col>
						</Row>
					</Container>
				</section>
			</Layout>
		</div>
	);
};

export default NinjaHalloweenMini;
